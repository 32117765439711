<template>
  <div class="py-[0.78rem]">
    <template>
      <div class="flex px-6 w-full justify-between items-center">
        <div class="w-full space-x-12 flex justify-center items-center">
          <div v-if="getLinks.totalItems > getLinks.limit">
            <TablePagination
              :clickedMethod="paginateLinks"
              :resultCount="getLinks.totalItems"
              :itemsPerPage="getLinks.limit"
              :currentPage="getLinks.page"
              :totalPagesNumber="getLinks.totalPages"
            ></TablePagination>
          </div>
        </div>
        <PerPageFilter
          :limit="getLinks.limit"
          @click="getLinks.limit = $event; paginateLinks()"
        ></PerPageFilter>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  components: {
    TablePagination: () => import('@/ui/ui-kit/v2/TablePagination.vue'),
    PerPageFilter: () => import('@/ui/ui-kit/v2/PerPageFilter.vue'),
  },
  computed: {
    ...mapGetters([
      'getLinks'
    ])
  }
}
</script>
